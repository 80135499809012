





























import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, watch } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import getComponentCacheKey from '~/helpers/caching/getComponentCacheKey';
import { useNavigationState } from '~/composables';
import NavMainCategories from '~/components/molecules/Navigation/NavMainCategories/NavMainCategories.vue';
import NavBody from '~/components/organisms/Navigation/NavBody.vue';
import { enableHTMLScroll, disableHTMLScroll } from '~/helpers/htmlScroll';

export default defineComponent({
  name: 'Navigation',
  components: {
    NavMainCategories,
    NavBody,
  },
  props: {
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
    componentCaching: {
      type: String,
      default: '',
    },
    topLevelDomain: {
      type: String,
      required: true,
    },
    languageAndCountry: {
      type: String,
      required: true,
    },
  },
  serverCacheKey(props: {
    categoriesContainer: Category[],
    componentCaching: string,
    topLevelDomain: string,
    languageAndCountry: string
  }) {
    return getComponentCacheKey({
      componentCaching: props.componentCaching,
      topLevelDomain: props.topLevelDomain,
      languageAndCountry: props.languageAndCountry,
    });
  },
  setup() {
    const {
      isNavigationOpen,
      currentFirstLevelCategorySlug,
      toggleNavigation,
    } = useNavigationState();

    watch(isNavigationOpen, (value) => {
      // if sidebar is open we want to disable the html scroll
      if (process?.server) return;
      if (value) {
        disableHTMLScroll();
      } else {
        enableHTMLScroll();
      }
    }, { immediate: true });

    return {
      currentFirstLevelCategorySlug,
      isNavigationOpen,
      toggleNavigation,
    };
  },
});
