
































































import type { ComputedRef, PropType } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n/types';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useVSFContext } from '@vue-storefront/core';
import { defineComponent, computed, ref, watch, onMounted } from '@nuxtjs/composition-api';
import once from 'lodash/once';
import { useI18n, useNavigationState } from '~/composables';
import NavCategoryListItem from '~/components/molecules/Navigation/NavCategoryList/NavCategoryListItem.vue';
import NavCategoryList from '~/components/molecules/Navigation/NavCategoryList/NavCategoryList.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import { sortCategories } from '~/helpers/category/sortCategories';
import { createCategoryBestsellersLink, createCategoryOnSaleLink } from '~/helpers/navigation/createNavLinks';
import NavigationLink from '~/components/molecules/Navigation/NavigationLink/NavigationLink.vue';
import { removeEmptyCategories } from '~/helpers/category/categoriesTree';
import { buildCategoryPageUrl } from '~/helpers/category/buildCategoryPageUrl';

export default defineComponent({
  name: 'NavFirstLevelCategory',
  components: {
    NavCategoryListItem,
    NavCategoryList,
    NavigationLink,
  },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true,
    },
  },
  setup(props) {
    const {
      currentSecondLevelCategoryName,
      currentFirstLevelCategorySlug,
      currentSecondLevelCategorySlug,
      setFirstLevelCategory,
    } = useNavigationState();
    const { i18n } = useVSFContext();
    const { languageAndCountry } = useI18n();

    const categoryTitle = computed(() => currentSecondLevelCategoryName.value || props.category.name);
    const categorySlug = computed(() => currentSecondLevelCategorySlug.value || props.category.slug);
    const targetCategoryUrl = computed(() => buildCategoryPageUrl(categorySlug.value));
    const canShowCategoryLink = computed(() => props.category.slug === currentFirstLevelCategorySlug.value);
    const secondLevelCategories = ref(props.category.children);
    const sortCategoriesOnce = once(() => {
      const onlyCategoriesWithProducts = removeEmptyCategories(secondLevelCategories.value, languageAndCountry.value);
      sortCategories(onlyCategoriesWithProducts);
      secondLevelCategories.value = onlyCategoriesWithProducts;
    });
    watch(canShowCategoryLink, () => {
      sortCategoriesOnce();
    });
    onMounted(() => {
      if (canShowCategoryLink.value) {
        sortCategoriesOnce();
      }
    });

    const onGoBack = () => {
      if (!currentSecondLevelCategoryName.value) {
        setFirstLevelCategory();
      }
    };

    const backButtonText: ComputedRef<TranslateResult> = computed(() => {
      if (!categoryTitle.value) {
        return i18n.t('Categories');
      } else {
        return categoryTitle.value;
      }
    });

    const categoryOnSaleLink = createCategoryOnSaleLink(props.category.slug);
    const categoryBestsellersLink = createCategoryBestsellersLink(props.category.slug);

    const bestsellerButtonProps = {
      text: i18n.t('bestseller'),
      url: categoryBestsellersLink,
    };
    const shopAllButtonProps = {
      'is-primary': true,
      text: i18n.t('Shop All'),
      url: targetCategoryUrl.value,
      'data-testid': 'showAllProducts',
    };
    const onSaleButtonProps = {
      text: i18n.t('on-sale'),
      url: categoryOnSaleLink,
    };
    return {
      currentSecondLevelCategoryName,
      LinkType,
      secondLevelCategories,
      onGoBack,
      backButtonText,
      categoryOnSaleLink,
      categoryBestsellersLink,
      canShowCategoryLink,
      bestsellerButtonProps,
      shopAllButtonProps,
      onSaleButtonProps,
    };
  },
});
